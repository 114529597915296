$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.container {
  position: relative;
  z-index: var(--b-z-index-4);

  transition: top 0.2s ease-in-out;

  @media #{$fromLaptop} {
    position: sticky;
    top: 0;

    &.withRibbon {
      // altura del ribbon = padding vertical de la frase + linea height de la frase
      padding-top: calc(var(--b-space-xs) * 2 + #{$line-height-m});
    }
  }
}

.hidden {
  @media #{$fromLaptop} {
    --fast-booking-box-shadow-height: 6px;
    --container-total-height: calc(
      var(--header-height) + var(--fast-booking-box-shadow-height)
    );

    top: calc(var(--container-total-height) * -1);
  }
}

.header {
  border-bottom: #{toRem(2)} solid transparent;
  padding-top: var(--b-space-xs);
  padding-bottom: var(--b-space-s);

  background-color: var(--b-color-icon-dark);
  border-image: var(--b-color-fill-ocean-100-gradient);
  border-image-slice: 1;

  @media #{$fromLaptop} {
    border-bottom: #{toRem(8)} solid transparent;
    padding-top: var(--b-space-l);
    padding-bottom: var(--b-space-l);
  }
}

.withFastBooking {
  border-bottom: #{toRem(8)} solid transparent;
  padding-bottom: var(--b-space-4xl);

  @media #{$fromLaptop} {
    padding-bottom: var(--b-space-2xl);
  }
}

.smallHeader {
  @media #{$onlyMobileAndTablet} {
    padding-top: var(--b-space-xxs);
    padding-bottom: var(--b-space-xxs);
  }
}

.contentWrapper {
  display: flex;
  gap: var(--b-space-xs);
  align-items: center;
  overflow: hidden;
}

.hotelName {
  display: block;
  overflow: hidden;

  width: 100%;
  padding-right: var(--b-space-xs);

  white-space: nowrap;
  text-overflow: ellipsis;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include contentContainer;
}

.menu {
  display: flex;
  align-items: center;

  @media #{$onlyMobileAndTablet} {
    margin-right: calc(var(--b-space-xs) * -1);
  }

  @media #{$fromLaptop} {
    gap: var(--b-space-xl);
    align-items: flex-end;
  }
}

.divider {
  align-self: center;

  width: #{toRem(1)};
  height: var(--b-space-m);

  background-color: var(--b-color-text-light);
}
