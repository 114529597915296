$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';

.appContainer {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.mainContainer {
  @include fullWidthContainerLateralPadding;
  flex-grow: 1;

  background-color: var(--b-color-bg-light);
}

.mainContainerConfirmationPage {
  @include fullWidthContainerLateralPadding;
  flex-grow: 1;

  background-color: var(--b-color-bg-white);
}
